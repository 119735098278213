
import React, { useEffect, useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/button';
import config from '../config'
import { createLookupStore, pathImg, request } from '../utils/api';
import { Popup } from 'devextreme-react';

export default function MyImageFileUploader({ cellInfo }) {
    const [image, setImage] = useState();
    useEffect(() => {
        (async function () {

            const data2 = await request(`Public/FileDetail?ID=${cellInfo.value}`);
            setImage(`${pathImg(data2.data?.LinkLuuTru)}`);
        })();
    }, [])
    const onUploaded = (e, cellInfo) => {
        let files = JSON.parse(e.request.responseText);
        cellInfo.setValue(files.docfiles[0].ID);
    };

    const onValueChanged = e => {
        const reader = new FileReader();
        reader.onload = function (args) {
            setImage(args.target.result);
        }
        reader.readAsDataURL(e.value[0]);
    };
    return (
        <>
            <img className="uploadedImage" width={100} src={image} />
            <FileUploader
                multiple={false}
                accept="image/*"
                uploadMode="instantly"
                uploadUrl={window.env.REACT_APP_API_URL + `/FileManager/FileImageUpload?Width=&Height=`}
                uploadHeaders={(()=>{
                    let access_token = localStorage.getItem("access_token");
                    return {
                        "Authorization": `Bearer ${access_token}`
                    }
                })()}
                onValueChanged={onValueChanged}
                onUploaded={e => onUploaded(e, cellInfo)}
            />
        </>
    )
}



// import React, { useEffect, useState } from 'react';
// import FileUploader from 'devextreme-react/file-uploader';
// import Button from 'devextreme-react/button';
// import config from '../config'
// import { createLookupStore, pathImg } from '../utils/api';
// import { Popup } from 'devextreme-react';

// const dataSourceOptions = createLookupStore("ImageSizes");


// export default function MyImageFileUploader({ cellInfo }) {
//     const [imageResize, setImageResize] = useState(false);
//     const [size, setSize] = useState();
//     const [imageSizes, setImageSizes] = useState();
//     const [image, setImage] = useState();
//     useEffect(() => {
//         dataSourceOptions.load().then(
//             (data) => {
//                 setImageSizes(data);
//             },
//             (error) => { });

//         (async function () {

//             const response2 = await fetch(`${window.env.REACT_APP_API_URL}/Public/FileDetail?ID=${cellInfo.value}`, {
//                 method: 'GET'
//             });
//             const data2 = await response2.json();
//             setImage(`${pathImg(data2.data.LinkLuuTru)}`);
//         })();
//     }, [])
//     const onUploaded = (e, cellInfo) => {
//         let files = JSON.parse(e.request.responseText);
//         cellInfo.setValue(files.docfiles[0].ID);
//     };

//     // let imgRef = React.createRef();

//     const onValueChanged = e => {
//         const reader = new FileReader();
//         reader.onload = function (args) {
//             setImage(args.target.result);
//             // imgRef.current.setAttribute('src', args.target.result);
//         }
//         reader.readAsDataURL(e.value[0]);
//         setImageResize(true);
//     };
//     return (
//         <>
//             <Popup
//                 visible={imageResize}
//                 dragEnabled={false}
//                 hideOnOutsideClick={false}
//                 showCloseButton={true}
//                 showTitle={true}
//                 title="Chọn kích cỡ ảnh"
//                 width={300}
//                 height={280}
//             >
//                 <input type={`radio`} name="imagesize" /> Giữ nguyên <br />
//                 {imageSizes && imageSizes.map((item, index) =>
//                     <>
//                         <input type={`radio`} name="imagesize" value={item.ID} onClick={() => {
//                             setSize(item);
//                         }} />
//                         {item.Name} ({item.Width} x {item.Height}  )<br />
//                     </>
//                 )
//                 }
//                 <Button onClick={() => {
//                     setImageResize(false);
//                 }}>
//                     Đồng ý
//                 </Button>
//             </Popup>
//             <img className="uploadedImage" width={100} src={image} />
//             <FileUploader
//                 multiple={false}
//                 accept="image/*"
//                 uploadMode="useButtons"
//                 uploadUrl={window.env.REACT_APP_BACKEND_URL + `/FileManager/FileImageUpload?Width=${size ? size.Width : ''}&Height=${size ? size.Height : ''}`}
//                 onValueChanged={onValueChanged}
//                 onUploaded={e => onUploaded(e, cellInfo)}
//             />
//         </>
//     )
// }
