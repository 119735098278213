
import React, { useEffect, useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/button';
import config from '../config'
import { createLookupStore, pathImg } from '../utils/api';
import { Popup } from 'devextreme-react';



export default function MyFileFileUploader({ cellInfo }) {
    const onUploaded = (e, cellInfo) => {
        let files = JSON.parse(e.request.responseText);
        cellInfo.setValue(files.files[0].ID);
    };
    return (
        <>
            <FileUploader
                name="fileupload"
                multiple={false}
                uploadMode="instantly"
                chunkSize={200000}
                uploadUrl={window.env.REACT_APP_API_ROOT_URL + `/Upload/FileUpload`}
                uploadHeaders={(()=>{
                    let access_token = localStorage.getItem("access_token");
                    return {
                        "Authorization": `Bearer ${access_token}`
                    }
                })()}
                onUploaded={e => onUploaded(e, cellInfo)}
            />
        </>
    )
}
