
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Header, SideNavigationMenu, Footer } from '../components';
import { useScreenSize } from '../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../utils/patches';
import Aside from '../components/Aside';
import Content from '../components/Content';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Popup,
  Lookup,
  Form,
  FilterRow, HeaderFilter,
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import LayoutAdmin from '../layouts/LayoutAdmin';
import config from '../config';
import { createCustomStore, createLookupStore } from '../utils/api';

const dataSourceABLChuyenMucAlbumOptions = createLookupStore("ABL_ChuyenMucAlbumAnh");


export default function ABL_AlbumAnh() {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Quản lý album</h3>
        <div className="card-tools">
          <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
            <i className="fas fa-minus" />
          </button>
          <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
            <i className="fas fa-times" />
          </button>
        </div>
      </div>
      <div className="card-body">
        <DataGrid
          dataSource={createCustomStore("ABL_AlbumAnh")} remoteOperations={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          showBorders={true}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowAdding={false}
            allowDeleting={false}>
            <Popup title="Thông tin" showTitle={true} />
            <Form>

              <Item dataField="TieuDe" />
              <Item dataField="MoTa" />
              <Item dataField="ThoiGianTao" />
              <Item dataField="TrangThai" />
              <Item dataField="IDChuyenMucAlbumAnh" />
              <Item dataField="TacGia" />
              <Item dataField="ThoiGianXuatBan" />
              <Item dataField="Link" />
            </Form>
          </Editing>

          <Column dataField="TieuDe" dataType="string" caption="Tiêu đề" width={300} />
          <Column dataField="MoTa" dataType="string" caption="Mô tả" visible={false} />
          <Column dataField="ThoiGianTao" dataType="datetime" caption="Thời gian tạo" sortOrder={"desc"} />
          <Column dataField="TrangThai" dataType="string" caption="Trạng thái">
            <Lookup allowClearing={true} searchEnabled={true} dataSource={[
              { Val: "HuyXuatBan", Name: "Hủy xuất bản" },
              { Val: "1", Name: "Xuất bản" },
              { Val: "ChoDuyet", Name: "Chờ duyệt" },
              { Val: "BiTraVe", Name: "Trả về" },
            ]} valueExpr="Val" displayExpr="Name" />
          </Column>
          <Column dataField="IDChuyenMucAlbumAnh" dataType="string" caption="Tên chuyên mục" >
            <Lookup allowClearing={true} searchEnabled={true} dataSource={dataSourceABLChuyenMucAlbumOptions} valueExpr="ID" displayExpr="TenChuyenMuc" />
          </Column>
          <Column dataField="TacGia" dataType="string" caption="Tác giả" visible={false} />
          <Column dataField="ThoiGianXuatBan" dataType="datetime" caption="Thời gian xuất bản" />
          <Column dataField="Link" dataType="string" caption="Link" visible={false} />
          <Pager allowedPageSizes={[20, 50, 100]} showPageSizeSelector={true} />
          <Paging defaultPageSize={20} />
        </DataGrid>
      </div>
      <div className="card-footer">
      </div>
    </div>

  );
}

