import { withNavigationWatcher } from './contexts/navigation';
import ABL_AlbumAnh from './pages/ABL_AlbumAnh';
import ABL_AlbumAudio from './pages//ABL_AlbumAudio';
import ABL_AlbumVideo from './pages//ABL_AlbumVideo';
import ABL_Anh from './pages/ABL_Anh';
import ABL_Audio from './pages/ABL_Audio';
import ABL_ChuyenMucAlbumAnh from './pages/ABL_ChuyenMucAlbumAnh';
import ABL_ChuyenMucAlbumAudio from './pages//ABL_ChuyenMucAlbumAudio';
import ABL_ChuyenMucAlbumVideo from './pages//ABL_ChuyenMucAlbumVideo';
import ABL_Video from './pages/ABL_Video';
import Banner from './pages/Banner';
import Banner_ViTri from './pages/Banner_ViTri';
import BinhChon_CauHoi from './pages/BinhChon_CauHoi';
import BinhChon_KetQua from './pages/BinhChon_KetQua';
import BinhChon_TraLoi from './pages/BinhChon_TraLoi';
import BinhLuan from './pages/BinhLuan';
import BuocQuyTrinh from './pages/BuocQuyTrinh';
import ChucDanh from './pages/ChucDanh';
import ChuyenMuc from './pages/ChuyenMuc';
import CKTD_VanBan from './pages/CKTD_VanBan';
import CoCauToChuc from './pages/CoCauToChuc';
import CoQuanBanHanh from './pages/CoQuanBanHanh';
import CrawlBVND from './pages/CrawlBVND';
import Crawler_ChuDe from './pages/Crawler_ChuDe';
import Crawler_LienKet from './pages/Crawler_LienKet';
import Crawler_Nguon from './pages/Crawler_Nguon';
import Crawler_NguonCha from './pages/Crawler_NguonCha';
import DangKyNhanMail from './pages/DangKyNhanMail';
import DanhNgon from './pages/DanhNgon';
import DM_DiaChi from './pages/DM_DiaChi';
import DM_GiaDat from './pages/DM_GiaDat';
import DM_GiaiDoan from './pages/DM_GiaiDoan';
import DM_LoaiDat from './pages/DM_LoaiDat';
import DM_LoaiTin from './pages/DM_LoaiTin';
import DongBoDuLieu from './pages/DongBoDuLieu';
import DongSuKien from './pages/DongSuKien';
import DonVi_LanhDao from './pages/DonVi_LanhDao';
import DTVB_CoQuanPhuTrach from './pages/DTVB_CoQuanPhuTrach';
import DTVB_DuThaoVanBan from './pages/DTVB_DuThaoVanBan';
import DTVB_LoaiDuThaoVanBan from './pages/DTVB_LoaiDuThaoVanBan';
import DTVB_YKienDongGop from './pages/DTVB_YKienDongGop';
import DuAnDauThau from './pages/DuAnDauThau';
import DVC_BuocQuyTrinh from './pages/DVC_BuocQuyTrinh';
import DVC_DichVuCong3 from './pages/DVC_DichVuCong3';
import DVC_DonViDangKy from './pages/DVC_DonViDangKy';
import DVC_FormMau from './pages/DVC_FormMau';
import DVC_HoSo from './pages/DVC_HoSo';
import DVC_NhomQuyen from './pages/DVC_NhomQuyen';
import DVC_QuyTrinh from './pages/DVC_QuyTrinh';
import DVC_ThongTinXuLyHoSo from './pages/DVC_ThongTinXuLyHoSo';
import DVC_TraoDoi from './pages/DVC_TraoDoi';
import DVC2_CoQuan from './pages/DVC2_CoQuan';
import DVC2_LinhVuc from './pages/DVC2_LinhVuc';
import DVC2_ThuTucHanhChinh from './pages/DVC2_ThuTucHanhChinh';
import File from './pages/File';
import File_DongBoDuLieu from './pages/File_DongBoDuLieu';
import GLTT_CauHoi from './pages/GLTT_CauHoi';
import GLTT_ChuDe from './pages/GLTT_ChuDe';
import GLTT_PhienGiaoLuu from './pages/GLTT_PhienGiaoLuu';
import HinhThucVanBan from './pages/HinhThucVanBan';
import HoiDap_CauHoi from './pages/HoiDap_CauHoi';
import HoiDap_ChuDe from './pages/HoiDap_ChuDe';
import HoiDap_DonVi from './pages/HoiDap_DonVi';
import HoiDap_TraLoi from './pages/HoiDap_TraLoi';
import LanhDao_ThoiKy from './pages/LanhDao_ThoiKy';
import LinhVuc from './pages/LinhVuc';
import Role from './pages/Role';
import LinhVucDuAn from './pages/LinhVucDuAn';
import LoaiDuAn from './pages/LoaiDuAn';
import LoaiSuKien from './pages/LoaiSuKien';
import Login from './pages/Login';
import LopVanBan from './pages/LopVanBan';
import Menu_Backend from './pages/Menu_Backend';
import Menu_FrontEnd from './pages/Menu_FrontEnd';
import NhomNhanVien from './pages/NhomNhanVien';
import NiemGiamThongKe from './pages/NiemGiamThongKe';
import BackUp from './pages/BackUp';
import QLDA_DonVi from './pages/QLDA_DonVi';
import QLDA_DuAnDeAn from './pages/QLDA_DuAnDeAn';
import QLDA_GiaiDoan from './pages/QLDA_GiaiDoan';
import QLDA_HoSo from './pages/QLDA_HoSo';
import QLDA_LinhVuc from './pages/QLDA_LinhVuc';
import QLDA_LoaiDuAn from './pages/QLDA_LoaiDuAn';
import QLDA_PhamVi from './pages/QLDA_PhamVi';
import QLDA_VanBan from './pages/QLDA_VanBan';
import QuangCao_ViTri from './pages/QuangCao_ViTri';
import QuaTrinhKiemDuyet from './pages/QuaTrinhKiemDuyet';
import QuaTrinhKiemDuyet_AlbumAnh from './pages/QuaTrinhKiemDuyet_AlbumAnh';
import QuaTrinhKiemDuyet_AlbumVideo from './pages/QuaTrinhKiemDuyet_AlbumVideo';
import QuyTrinhKiemDuyet from './pages/QuyTrinhKiemDuyet';
import SuKienHoiThao from './pages/SuKienHoiThao';
import ThamSoHeThong from './pages/ThamSoHeThong';
import ThiTruongLaoDong from './pages/ThiTruongLaoDong';
import ThoiKyPhatTrien from './pages/ThoiKyPhatTrien';
import ThongTinDoanhNghiep from './pages/ThongTinDoanhNghiep';
import ThongTinLanhDao from './pages/ThongTinLanhDao';
import ThongTinUyQuyen from './pages/ThongTinUyQuyen';
import TinTuc from './pages/TinTuc';
import TinTuc_Temp from './pages/TinTuc_Temp';
import User_Account from './pages/User_Account';
import User_DichVuCong from './pages/User_DichVuCong';
import USR_LinkQuanLy from './pages/USR_LinkQuanLy';
import VanBan from './pages/VanBan';
import VDO_ChuyenMucVideo from './pages/VDO_ChuyenMucVideo';
import VDO_Video from './pages/VDO_Video';
import VungHienThi from './pages/VungHienThi';
import Home from './pages/Home';
import ForgotPassword from './pages/ForgotPassword';
import MediaFileManager from './pages/MediaFileManager';
import ImageSize from './pages/ImageSize';
import ADConfig from './pages/ADConfig';
import DanhMucThongTin from './pages/DanhMucThongTin';
import Block from './pages/Block';
import BlockConfig from './pages/BlockConfig';
import Template from './pages/Template';
import Profile from './pages/Profile';
import DiffPage from './pages/DiffPage';

const routes = [
    { path: '/ABL_AlbumAnh', element: ABL_AlbumAnh },
    { path: '/ABL_AlbumAudio', element: ABL_AlbumAudio },
    { path: '/ABL_AlbumVideo', element: ABL_AlbumVideo },
    { path: '/ABL_Anh', element: ABL_Anh },
    { path: '/ABL_Audio', element: ABL_Audio },
    { path: '/AlbumAnh/ABL_ChuyenMucAlbumAnh', element: ABL_ChuyenMucAlbumAnh },
    { path: '/AlbumAudio/ABL_ChuyenMucAlbumAudio', element: ABL_ChuyenMucAlbumAudio },
    { path: '/AlbumVideo/ABL_ChuyenMucAlbumVideo', element: ABL_ChuyenMucAlbumVideo },
    { path: '/ABL_Video', element: ABL_Video },
    { path: '/Banner/Banner', element: Banner },
    { path: '/Banner/Banner_ViTri', element: Banner_ViTri },
    { path: '/BinhChon_CauHoi', element: BinhChon_CauHoi },
    { path: '/BinhChon_KetQua', element: BinhChon_KetQua },
    { path: '/BinhChon_TraLoi', element: BinhChon_TraLoi },
    { path: '/BinhLuan', element: BinhLuan },
    { path: '/BuocQuyTrinh', element: BuocQuyTrinh },
    { path: '/System/ChucDanh', element: ChucDanh },
    { path: '/ListNews/ChuyenMuc', element: ChuyenMuc },
    { path: '/CKTD_VanBan', element: CKTD_VanBan },
    { path: '/CoCauToChuc', element: CoCauToChuc },
    { path: '/VanBan/CoQuanBanHanh', element: CoQuanBanHanh },
    { path: '/CrawlBVND', element: CrawlBVND },
    { path: '/Crawler_ChuDe', element: Crawler_ChuDe },
    { path: '/Crawler_LienKet/:type?', element: Crawler_LienKet },
    { path: '/Crawler_Nguon', element: Crawler_Nguon },
    { path: '/Crawler_NguonCha', element: Crawler_NguonCha },
    { path: '/DangKyNhanMail', element: DangKyNhanMail },
    { path: '/DanhNgon', element: DanhNgon },
    { path: '/DM_DiaChi', element: DM_DiaChi },
    { path: '/DM_GiaDat', element: DM_GiaDat },
    { path: '/DM_GiaiDoan', element: DM_GiaiDoan },
    { path: '/DM_LoaiDat', element: DM_LoaiDat },
    { path: '/DM_LoaiTin', element: DM_LoaiTin },
    { path: '/DongBoDuLieu', element: DongBoDuLieu },
    { path: '/ListNews/DongSuKien', element: DongSuKien },
    { path: '/DonVi_LanhDao', element: DonVi_LanhDao },
    { path: '/DTVB_CoQuanPhuTrach', element: DTVB_CoQuanPhuTrach },
    { path: '/DTVB_DuThaoVanBan', element: DTVB_DuThaoVanBan },
    { path: '/DTVB_LoaiDuThaoVanBan', element: DTVB_LoaiDuThaoVanBan },
    { path: '/DTVB_YKienDongGop', element: DTVB_YKienDongGop },
    { path: '/DuAnDauThau', element: DuAnDauThau },
    { path: '/DVC_BuocQuyTrinh', element: DVC_BuocQuyTrinh },
    { path: '/DVC_DichVuCong3', element: DVC_DichVuCong3 },
    { path: '/DVC_DonViDangKy', element: DVC_DonViDangKy },
    { path: '/DVC_FormMau', element: DVC_FormMau },
    { path: '/DVC_HoSo', element: DVC_HoSo },
    { path: '/DVC_NhomQuyen', element: DVC_NhomQuyen },
    { path: '/DVC_QuyTrinh', element: DVC_QuyTrinh },
    { path: '/DVC_ThongTinXuLyHoSo', element: DVC_ThongTinXuLyHoSo },
    { path: '/DVC_TraoDoi', element: DVC_TraoDoi },
    { path: '/DVC2_CoQuan', element: DVC2_CoQuan },
    { path: '/DVC2_LinhVuc', element: DVC2_LinhVuc },
    { path: '/DVC2_ThuTucHanhChinh', element: DVC2_ThuTucHanhChinh },
    { path: '/File', element: File },
    { path: '/File_DongBoDuLieu', element: File_DongBoDuLieu },
    { path: '/GLTT_CauHoi', element: GLTT_CauHoi },
    { path: '/GLTT_ChuDe', element: GLTT_ChuDe },
    { path: '/GLTT_PhienGiaoLuu', element: GLTT_PhienGiaoLuu },
    { path: '/VanBan/HinhThucVanBan', element: HinhThucVanBan },
    { path: '/HoiDap/HoiDap_CauHoi', element: HoiDap_CauHoi },
    { path: '/HoiDap/HoiDap_ChuDe', element: HoiDap_ChuDe },
    { path: '/HoiDap/HoiDap_DonVi', element: HoiDap_DonVi },
    { path: '/HoiDap/HoiDap_TraLoi', element: HoiDap_TraLoi },
    { path: '/LanhDao_ThoiKy', element: LanhDao_ThoiKy },
    { path: '/VanBan/LinhVuc', element: LinhVuc },
    { path: '/LinhVucDuAn', element: LinhVucDuAn },
    { path: '/LoaiDuAn', element: LoaiDuAn },
    { path: '/Event/LoaiSuKien', element: LoaiSuKien },
    { path: '/Login', element: Login },
    { path: '/ForgotPassword', element: ForgotPassword },
    { path: '/VanBan/LopVanBan', element: LopVanBan },
    { path: '/Menu_Backend', element: Menu_Backend },
    { path: '/System/Menu_FrontEnd', element: Menu_FrontEnd },
    { path: '/System/NhomNhanVien', element: NhomNhanVien },
    { path: '/System/NiemGiamThongKe', element: NiemGiamThongKe },
    { path: '/System/BackUp', element: BackUp },
    { path: '/QLDA/QLDA_DonVi', element: QLDA_DonVi },
    { path: '/QLDA/QLDA_DuAnDeAn', element: QLDA_DuAnDeAn },
    { path: '/QLDA/QLDA_GiaiDoan', element: QLDA_GiaiDoan },
    { path: '/QLDA/QLDA_HoSo', element: QLDA_HoSo },
    { path: '/QLDA/QLDA_LinhVuc', element: QLDA_LinhVuc },
    { path: '/QLDA/QLDA_LoaiDuAn', element: QLDA_LoaiDuAn },
    { path: '/QLDA/QLDA_PhamVi', element: QLDA_PhamVi },
    { path: '/QLDA/QLDA_VanBan', element: QLDA_VanBan },
    { path: '/Banner/QuangCao_ViTri', element: QuangCao_ViTri },
    { path: '/QuaTrinhKiemDuyet', element: QuaTrinhKiemDuyet },
    { path: '/QuaTrinhKiemDuyet_AlbumAnh', element: QuaTrinhKiemDuyet_AlbumAnh },
    { path: '/QuaTrinhKiemDuyet_AlbumVideo', element: QuaTrinhKiemDuyet_AlbumVideo },
    { path: '/QuyTrinhKiemDuyet', element: QuyTrinhKiemDuyet },
    { path: '/SuKienHoiThao', element: SuKienHoiThao },
    { path: '/System/ThamSoHeThong', element: ThamSoHeThong },
    { path: '/System/Role', element: Role },
    { path: '/ThiTruongLaoDong', element: ThiTruongLaoDong },
    { path: '/ThoiKyPhatTrien', element: ThoiKyPhatTrien },
    { path: '/DanhMucThongTin', element: DanhMucThongTin },
    { path: '/ThongTinDoanhNghiep', element: ThongTinDoanhNghiep },
    { path: '/ThongTinLanhDao', element: ThongTinLanhDao },
    { path: '/ThongTinUyQuyen', element: ThongTinUyQuyen },
    { path: '/News/TinTuc/:type?', element: TinTuc },
    { path: '/News/TinTuc_Temp', element: TinTuc_Temp },
    { path: '/System/User_Account', element: User_Account },
    { path: '/User_DichVuCong', element: User_DichVuCong },
    { path: '/USR_LinkQuanLy', element: USR_LinkQuanLy },
    { path: '/VanBan/VanBan/:IdLopVanBan?', element: VanBan },
    { path: '/VDO_ChuyenMucVideo', element: VDO_ChuyenMucVideo },
    { path: '/VDO_Video', element: VDO_Video },
    { path: '/ListNews/VungHienThi', element: VungHienThi },
    { path: '/System/ImageSize', element: ImageSize },
    { path: '/System/ADConfig', element: ADConfig },
    { path: '/MediaFileManager', element: MediaFileManager },
    { path: '/Block', element: Block },
    { path: '/BlockConfig', element: BlockConfig },
    { path: '/Template', element: Template },
    { path: '/Profile', element: Profile },
    { path: '/', element: Home },
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
