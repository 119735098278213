
import React, { useEffect, useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/button';
import config from '../config'
import { createLookupStore, notify, pathImg, request } from '../utils/api';
import { Popup, TextArea, TextBox } from 'devextreme-react';
import { useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';

export default function SendApprovePopup({ ID, action, onHidden }) {
    const [item, setItem] = useState();
    const [comment, setComment] = useState();
    const [popupVisible, setPopupVisible] = useState();

    useEffect(() => {
        (async function () {
            if (ID == undefined) {
                return;
            }
            setPopupVisible(true);
            const item = await request(`/TinTucs/Get?type=undefined&skip=0&take=20&requireTotalCount=true&filter=[["ID","=","${ID}"]]`);
            setItem(item);
        })();
    }, [])
    return (
        <Popup
            showTitle={true}
            title="Xác nhận"
            hideOnOutsideClick={true}
            visible={popupVisible}
            onHidden={() => {
                setPopupVisible(false);
                onHidden();
            }}
            contentRender={() => {
                return (
                    <>
                        {item && item.data.length > 0 &&
                            <>
                                <h4>{item.data[0].TieuDe}</h4>
                                <p>{parse(item.data[0].MoTa)}</p>
                                <h5>Ghi chú</h5>
                                <TextArea onValueChange={(e) => {
                                    setComment(e);
                                }} />
                                <br />
                                <Button
                                    type='success'
                                    onClick={async (e) => {
                                        const item = await request(`/TinTucs/Action?ID=${ID}&action=${action}&comment=${comment}`);
                                        if (item.isOk == false) {
                                            notify("Thao tác thất bại!", "error");
                                            return;
                                        }
                                        notify("Thao tác thành công!");
                                        setPopupVisible(false);
                                    }}>
                                    Đồng ý
                                </Button>
                                <Button onClick={async (e) => {
                                    setPopupVisible(false);
                                }}>
                                    Tạm hủy
                                </Button>
                            </>
                        }
                    </>
                );
            }}
        >
        </Popup>
    );
}
