
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Header, Footer } from '../components';
import { useScreenSize } from '../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../utils/patches';
import Aside from '../components/Aside';
import Content from '../components/Content';
import { LoadPanel } from 'devextreme-react';

import { Outlet } from "react-router-dom";


export default function LayoutAdminWithoutAside({ title, children }) {

  return (
    <>
      <Outlet />
    </>
  );
}
