import React, { useState, useCallback, useRef, useEffect } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import { Outlet, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/auth';


const MenuItem = ({ path, icon="fas fa-angle-right nav-icon", linkClass=`nav-link`, permission, title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const auth = useAuth();

    function isHavePermission(name) {
        if (!auth.user) {
            return false;
        }
        if (auth.user?.Role?.RolePermissions?.length <= 0) {
            return false;
        }
        if (auth.user.Role.RolePermissions == undefined) {
            auth.signOut();
        }
        for (var i = 0; i < auth.user.Role.RolePermissions.length; i++) {
            let element = auth.user.Role.RolePermissions[i];
            if (element.Permission.Name == name) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            {(permission != undefined && isHavePermission(permission) || permission == undefined) &&
                <li className={`nav-item ${isOpen?'menu-open':''}`}>
                    <Link to={path} className={linkClass} onClick={(e)=>{
                            if(children && children.length > 0){
                                e.preventDefault();
                                e.stopPropagation();
                                setIsOpen(!isOpen);
                            }
                        }}>
                        <i className={icon}></i>
                        <p>{title}
                            {
                                children && <i className="right fas fa-angle-left"></i>
                            }
                        </p>
                    </Link>
                    {children &&
                        <ul className="nav nav-treeview">
                            {children}
                        </ul>
                    }
                </li>
            }
        </>
    )
}
export default function Aside() {
    const location = useLocation();
    const auth = useAuth();
    useEffect(() => {
        window.$('[data-widget="treeview"]').each(function () {
            if (window.$(this).data('treeview-init') === undefined) {
                window.$(this).Treeview('init');
                window.$(this).data('treeview-init', true);
            }
        });
    }, []);
    // console.log(auth.user);
    return (
        <aside className="main-sidebar sidebar-light-light elevation-4">
            <div className="navbar-brand d-flex justify-content-center">
                <a href="/" className="app-logo brand-link">
                    <img src="/logo.png" alt="App Logo" className="img-fluid" />
                </a>
            </div>
            <div className="sidebar">
                <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw" />
                            </button>
                        </div>
                    </div><div className="sidebar-search-results"><div className="list-group"><a href="#" className="list-group-item"><div className="search-title"><strong className="text-light" />N<strong className="text-light" />o<strong className="text-light" /> <strong className="text-light" />e<strong className="text-light" />l<strong className="text-light" />e<strong className="text-light" />m<strong className="text-light" />e<strong className="text-light" />n<strong className="text-light" />t<strong className="text-light" /> <strong className="text-light" />f<strong className="text-light" />o<strong className="text-light" />u<strong className="text-light" />n<strong className="text-light" />d<strong className="text-light" />!<strong className="text-light" /></div><div className="search-path" /></a></div></div>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role={"menu"}>
                        <MenuItem icon='fas fa-chart-line nav-icon' title={'Dashboard'} path={"/"} />
                        <MenuItem icon='fas fa-newspaper nav-icon' permission={"manage-tintuc"} title={'QL tin tức'}>
                            <MenuItem linkClass="nav-link pl-2" title={'Tin tức'} path={"/News/TinTuc"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS tin của tôi'} path={"/News/TinTuc/owner"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS tin xuất bản của tôi'} path={"/News/TinTuc/publish"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS tin gửi duyệt của tôi'} path={"/News/TinTuc/wait"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS tin bị trả về của tôi'} path={"/News/TinTuc/cancel"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS tin lưu nháp của tôi'} path={"/News/TinTuc/draft"} />

                            <MenuItem linkClass="nav-link pl-2" title={'DS tin đang kiểm duyệt'} path={"/News/TinTuc/approving"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS tin chờ tôi xuất bản'} path={"/News/TinTuc/ownerApproving"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS tin xuất bản / hủy xuất bản'} path={"/News/TinTuc/publishUnPublish"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS tin emagazine'} path={"/News/TinTuc/emagazine"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS chuyên mục'} path={"/ListNews/ChuyenMuc"} />

                        </MenuItem>
                        <MenuItem icon='fas fa-store nav-icon' permission={"manage-thitruong"} title={'QL thị trường lao động'} path={"/ThiTruongLaoDong"} />

                        <MenuItem icon='fas fa-book nav-icon' permission={"manage-vanban"} title={'QL văn bản'}>
                            <MenuItem linkClass="nav-link pl-2" title={'QL văn bản QPPL'} path={"/VanBan/VanBan/101"} />
                            <MenuItem linkClass="nav-link pl-2" title={'QL văn bản CĐĐH'} path={"/VanBan/VanBan/104"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS văn bản Hợp tác quốc tế'} path={"/VanBan/VanBan/2125"} />
                            
                            <MenuItem linkClass="nav-link pl-2" title={'DS lĩnh vực văn bản'} path={"/VanBan/LinhVuc"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS hình thức văn bản'} path={"/VanBan/HinhThucVanBan"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS lớp văn bản'} path={"/VanBan/LopVanBan"} />
                            <MenuItem linkClass="nav-link pl-2" title={'DS Cơ quan ban hành'} path={"/VanBan/CoQuanBanHanh"} />
                        </MenuItem>
                        <MenuItem icon='fas fa-sitemap nav-icon' permission={"manage-cocautochuc"} title={'QL cơ cấu tổ chức'}>
                            <MenuItem linkClass="nav-link pl-2" title={'QL cơ cấu tổ chức'} path={"/CoCauToChuc"} />
                            <MenuItem linkClass="nav-link pl-2" title={'QL lãnh đạo bộ'} path={"/ThongTinLanhDao"} />
                            <MenuItem linkClass="nav-link pl-2" title={'QL Chức danh'} path={"/System/ChucDanh"} />
                        </MenuItem>

                        <MenuItem icon='fas fa fa-ad nav-icon' permission={"manage-banner"} title={'QL banner'}>
                            <MenuItem linkClass="nav-link pl-2" title={'QL banner'} path={"/Banner/Banner"} />
                            <MenuItem linkClass="nav-link pl-2" title={'QL vị trí'} path={"/Banner/QuangCao_ViTri"} />
                        </MenuItem>
                        <MenuItem icon='fas fa fa-history nav-icon' permission={"manage-thoiky"} title={'QL thời kì phát triển'} path={"/ThoiKyPhatTrien"} />

                        <MenuItem icon='fas fa-sms nav-icon' permission={"manage-danhngon"} title={'QL danh ngôn'} path={"/DanhNgon"} />


                        <MenuItem icon='fas fa-tasks nav-icon' permission={"manage-chuongtrinh"} title={'QL chương trình đề án, dự án'}>
                            <MenuItem linkClass="nav-link pl-2" title={'DS Chương trình, Đề án, Dự án'} path={"/QLDA/QLDA_DuAnDeAn"} />
                            <MenuItem linkClass="nav-link pl-2" title={'QL loại dự án'} path={"/QLDA/QLDA_LoaiDuAn"} />
                            <MenuItem linkClass="nav-link pl-2" title={'QL lĩnh vực'} path={"/QLDA/QLDA_LinhVuc"} />
                            <MenuItem linkClass="nav-link pl-2" title={'QL đơn vị'} path={"/QLDA/QLDA_DonVi"} />
                        </MenuItem>
                        <MenuItem icon='fas fa-video nav-icon' permission={"manage-tintuc"} title={'QL Media'}>
                            <MenuItem permission={"manage-tintuc"} title={'QL video tiếng việt'} path={"/Crawler_LienKet/1"} />
                            <MenuItem permission={"manage-tintuc"} title={'QL video tiếng anh'} path={"/Crawler_LienKet/57"} />
                            <MenuItem permission={"manage-tintuc"} title={'QL Audio'} path={"/Crawler_LienKet/58"} />
                            <MenuItem permission={"manage-tintuc"} title={'QL Ảnh'} path={"/Crawler_LienKet/59"} />

                        </MenuItem>

                        <MenuItem icon='fas fa-list nav-icon' permission={"manage-danhmuc"} title={'QL danh mục thông tin'} path={"/DanhMucThongTin"} />
                        <MenuItem icon='fas fa-calendar nav-icon' permission={"manage-sukien"} title={'QL sự kiện hội thảo'} path={"/SuKienHoiThao"} />

                        <li className="nav-header">SYSTEMS</li>


                        {/* <MenuItem title={'QL lĩnh vực dự án'} active={location.pathname == "/LinhVucDuAn"} path={"/LinhVucDuAn"} /> */}


                        <MenuItem icon='fas fa-cogs nav-icon' title={'Hệ thống'}>
                            <MenuItem icon='fas fa-users nav-icon' permission={"manage-user"} title={'QL người dùng'} path={"/System/User_Account"} />
                            <MenuItem icon='fas fa-user-tag nav-icon' permission={"manage-role"} title={'QL vai trò'} path={"/System/Role"} />
                            <MenuItem icon='fas fa-list nav-icon' permission={"manage-menu"} title={'QL menu frontend'} path={"/System/Menu_FrontEnd"} />
                            <MenuItem icon='fas fa-list nav-icon' permission={"manage-backup"} title={'QL backup'} path={"/System/Backup"} />
                            <MenuItem icon='fas fa-list nav-icon' permission={"manage-setting"} title={'Cấu hình tham số hệ thống'} path={"/System/ThamSoHeThong"} />
                            <MenuItem icon='fas fa-list nav-icon' permission={"manage-role"} title={'QL kích cỡ ảnh'} path={"/System/ImageSize"} />
                            <MenuItem icon='fas fa-list nav-icon' permission={"manage-role"} title={'QL chuỗi AD'} path={"/System/ADConfig"} />
                            <MenuItem icon='fas fa-list nav-icon' permission={"manage-block"} title={'QL khối hiển thị'} path={"/Block"} />
                            <MenuItem icon='fas fa-list nav-icon' permission={"manage-block"} title={'QL cấu hình hiển thị'} path={"/BlockConfig"} />
                            <MenuItem icon='fas fa-list nav-icon' permission={"manage-template"} title={'QL mẫu emagazine'} path={"/Template"} />
                            <MenuItem icon='fas fa-photo-video nav-icon' permission={"manage-library"} title={'QL thư viện'} path={"/MediaFileManager"} />
                            <MenuItem icon='fas fa-stream nav-icon' permission={"manage-quytrinh"} title={'QL quy trình kiểm duyệt'} path={"/QuyTrinhKiemDuyet"} />
                        </MenuItem>
                        {/* <MenuItem icon='fas fa-spider nav-icon' permission={"manage-crawler"} title={'QL Crawler'}>
                            <MenuItem linkClass="nav-link pl-2" permission={"manage-crawler"} title={'QL nguồn'} path={"/Crawler_Nguon"} />
                        </MenuItem> */}
                    </ul>
                </nav>
            </div>
        </aside>
    )
}
