
import React, { useEffect, useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/button';
import config from '../config'
import { createLookupStore, notify, pathImg, request } from '../utils/api';
import { Popup, TextBox } from 'devextreme-react';
import { useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';

export default function ActionPopup({ ID, action, onHidden }) {
    const [popupVisible, setPopupVisible] = useState();
    useEffect(() => {
        setPopupVisible(true);
    }, [action])
    return (
        <Popup
            showTitle={true}
            title="Xác nhận"
            hideOnOutsideClick={true}
            visible={popupVisible}
            onHidden={() => {
                setPopupVisible(false);
                onHidden();
            }}
            position={{
                my: 'center',
                at: 'center',
                of: window
            }}
            contentRender={() => {
                return (
                    <>
                        <h5>Bạn có đồng ý thực hiện hành động này?</h5>
                        <br />
                        <Button
                            type='success'
                            onClick={async (e) => {
                                const item = await request(`/TinTucs/Action?ID=${ID}&action=${action}`);
                                if (item.isOk == false) {
                                    notify("Thao tác thất bại!", "error");
                                    return;
                                }
                                notify("Thao tác thành công!");
                                setPopupVisible(false);
                            }}>
                            Đồng ý
                        </Button>
                        <Button onClick={async (e) => {
                            setPopupVisible(false);
                        }}>
                            Tạm hủy
                        </Button>
                    </>
                );
            }}
        >
        </Popup>
    );
}
