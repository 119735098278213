import React, { useEffect, useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/button';
import config from '../config'
import { createLookupStore } from '../utils/api';
import { Popup } from 'devextreme-react';



export default function MyVideoUploader({ onUploaded }) {

    return (
        <>
            <FileUploader
                multiple={false}
                uploadMode="instantly"
                uploadUrl={window.env.REACT_APP_API_URL + `/FileManager/VideoUpload`}
                onUploaded={onUploaded}
                uploadHeaders={(()=>{
                    let access_token = localStorage.getItem("access_token");
                    return {
                        "Authorization": `Bearer ${access_token}`
                    }
                })()}
            />
        </>
    )
}
