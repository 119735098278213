
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Header, SideNavigationMenu, Footer } from '../components';
import { useScreenSize } from '../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../utils/patches';
import Aside from '../components/Aside';
import Content from '../components/Content';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Popup,
  Lookup,
  Form,
  FilterRow, HeaderFilter, Button,
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import LayoutAdmin from '../layouts/LayoutAdmin';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import MyImageUploader from '../components/MyImageUploader';

const dataSourceOptions = createCustomStore("BackUps", "path");


export default function BackUp() {
  const datagrid = useRef(null);
  async function performBackUp() {
    const response = await request(`/BackUps/Post`);
    datagrid.current._instance.refresh();
  }
  async function performDownload(e) {
    window.location = `${window.env.REACT_APP_API_URL}/BackUps/Download?path=${e.row.data.path}`;
  }
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Quản lý backup</h3>
        <div className="card-tools">
          <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
            <i className="fas fa-minus" />
          </button>
          <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
            <i className="fas fa-times" />
          </button>
        </div>
      </div>
      <div className="card-body">
        <button type="button" className="btn btn-primary" onClick={performBackUp}>
          Backup
        </button>
        <DataGrid
          ref={datagrid}
          dataSource={dataSourceOptions}
          remoteOperations={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          showBorders={true}
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <Column dataField="path" dataType="string" caption="Đường dẫn" />

          <Column type="buttons" width={110}>
            <Button hint="Download" icon="download" onClick={performDownload} />
          </Column>
          <Pager allowedPageSizes={[20, 50, 100]} showPageSizeSelector={true} />
          <Paging defaultPageSize={20} />
        </DataGrid>
      </div>
      <div className="card-footer">
      </div>
    </div>

  );
}

