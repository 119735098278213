
import React, { useEffect, useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';

import config from '../config'
import { createLookupStore, notify, pathImg, request } from '../utils/api';
import { Popup, TextArea, TextBox } from 'devextreme-react';
import { useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';

export default function ApprovePopup({ ID, onHidden }) {
    const [item, setItem] = useState();
    const [comment, setComment] = useState();

    useEffect(() => {
        (async function () {
            if (ID == undefined) {
                return;
            }
            const item = await request(`/TinTucs/Get?type=undefined&skip=0&take=20&requireTotalCount=true&filter=[["ID","=","${ID}"]]`);
            setItem(item);
        })();
    }, [])

    function closePopup(){
        setItem();
        onHidden();
    }
    return (
        <>
        {item && item.data.length > 0 &&
            <Popup
                showTitle={true}
                fullScreen={true}
                title="Phê duyệt tin tức"
                hideOnOutsideClick={true}
                showCloseButton={true}
                visible={true}
                onHidden={() => {
                    closePopup()
                }}
                contentRender={() => {
                    return (
                        <ScrollView>
                            <h4>{item.data[0].TieuDe}</h4>
                            <div>{parse(item.data[0].MoTa?item.data[0].MoTa:"")}</div>
                            <h5>Ghi chú</h5>
                            <TextArea onValueChange={(e) => {
                                setComment(e);
                            }} />
                            <br />
                            <Button
                                type='success'
                                onClick={async (e) => {
        
                                    const item = await request(`/TinTucs/Action?ID=${ID}&action=approve&comment=${comment}`);
                                    if (item.isOk == false) {
                                        notify("Phê duyệt thất bại!", "error");
                                        return;
                                    }
                                    notify("Phê duyệt thành công!");
                                    closePopup()
                                }}>
                                Đồng ý
                            </Button>
                            <Button
                                type='danger'
                                onClick={async (e) => {
                                    const item = await request(`/TinTucs/Action?ID=${ID}&action=reject&comment=${comment}`);
                                    if (item.isOk == false) {
                                        notify("Hủy phê duyệt thất bại!", "error");
                                        return;
                                    }
                                    notify("Hủy phê duyệt thành công!");
                                    closePopup();
                                }}>
                                Từ chối duyệt
                            </Button>
                            <Button onClick={async (e) => {
                                closePopup();
                            }}>
                                Tạm bỏ qua
                            </Button>
                        </ScrollView>
                    );
                }}
            >
            </Popup>
        }
        </>
    );
}
